require('./bootstrap');

const sideMenu = document.querySelector('#side-menu')
const hamburgerMenu = document.querySelector('[data-js="hamburger-menu"]')

const hideShowSideMenu = () => {
    sideMenu.classList.toggle('show-side-menu')
}

hamburgerMenu && hamburgerMenu.addEventListener('click', hideShowSideMenu)
